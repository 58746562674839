<template>
  <div class="pb-6">
    <h1 class="text-h4 d-inline-flex">
      <v-icon color="secondary" size="40" class="pe-2">
        {{ icon }}
      </v-icon>
      {{ headline }}
    </h1>
    <v-btn @click="buttonClick()" class="btn text-uppercase primary ms-3 mb-5" style="" v-if="buttonText && entity !== 'invoice'"
           id="createEntityButton">
      Erstellen
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-btn @click="dialogImport =true" class="btn text-uppercase  ms-3 mb-5" style="" v-if="buttonText && entity && entity !== 'invoice'"
           id="createEntityButton" >
      Import
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-btn @click="buttonClick()" class="btn text-uppercase primary ms-3 mb-5" style="" v-if="entity === 'invoice'"
           id="createEntityButton" >
      Rechnung
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-btn @click="button2Click()" class="btn text-uppercase  ms-3 mb-5" style="" v-if="entity === 'invoice'"
           id="createEntityButton" >
        Angebot
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <ExcelImport
        :dialog="dialogImport"
        :entity="entity"
        @hideDialog="dialogImport=false"
    ></ExcelImport>
  </div>
</template>

<script>
const ExcelImport = () => import("@/components/imAndExport/ExcelImport");

export default {
  name: "HeadlineTableView",
  data() {
    return {
      dialogImport: false,
      dialogImport2: false
    }
  },
  props: {
    icon: String,
    headline: String,
    buttonText: String,
    buttonIcon: String,
    entity: String
  },
  methods: {
    buttonClick() {
      this.$emit('buttonClick')
    },
    button2Click() {
      this.$emit('button2Click')
    },
  },
  components: {
    ExcelImport
  }
}
</script>
